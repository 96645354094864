import Layout from "./common/layout"
import Blog from "./company/Blog/Blog"
import Head from "./common/Head"

const CompanyBlog = () => {
  return (
    <>
      <Head showCaptcha={true} />
      <Layout>
        <Blog />
      </Layout>
    </>
  )
}

export default CompanyBlog
